<template>
    <ki
            :user="getUSER"
            :reason="reason"
            :timer="timer"
            :showChecking="showChecking"
            :files="_Files"
            :own_securities_6_million_rubles="draft.own_securities_6_million_rubles"
            :have_experience="draft.have_experience"
            :have_experience_least_two_years="draft.have_experience_least_two_years"
            :have_experience_least_three_years="draft.have_experience_least_three_years"
            :have_cb_rf="draft.have_cb_rf"
            :made_securities_transactions="draft.made_securities_transactions"
            :own_property="draft.own_property"
            :higher_economic_education="draft.higher_economic_education"
            :higher_economic_education_certificate_qualification="draft.higher_economic_education_certificate_qualification"
            :higher_economic_education_CFA="draft.higher_economic_education_CFA"
            :higher_economic_education_CIIA="draft.higher_economic_education_CIIA"
            :higher_economic_education_FRM="draft.higher_economic_education_FRM"
            @submit="onSibmit"
            @verified="onVerifiedCode"
            @update="onUpdate"
            @timer="timerIsOver"

            @fileLoad="onFileLoad"
            @fileDelete="onFileDelete"
    />
</template>
<script>
    import {mapActions, mapGetters} from "vuex";
    import Ki from "@/components/pages/cabinet/documents/forms/Ki";
    import moment from "moment";
    import RWMSendingDocumentsMessageMixin from "../../../../components/mixins/RWMSendingDocumentsMessageMixin";

    let model = {};

    export default {
        name: "FormKi",
        components: {Ki},
        mixins: [RWMSendingDocumentsMessageMixin],
        computed: {
            ...mapGetters("errorModule", ["getERROR"]),
            ...mapGetters("AuthModule", ["getUSER"]),
            ...mapGetters("DocumentsModule", ["getDRAFT"]),
            reason() {
                return this.getDRAFT?.data?.types_of_services || 'Инвестиционные паи паевых инвестиционных фондов под управлением ЗАО УК «РВМ Капитал»';
            },
            draft() {
                return this.getDRAFT ? this.getDRAFT.data : {};
            },
            _Files: {
                get() {
                    if (
                        this.getDRAFT && this.getDRAFT.files &&
                        this.getDRAFT.files.length > 0
                    ) {
                        return this.getDRAFT.files
                    } else {
                        return this.Certificate;
                    }
                },
            },
        },
        data() {
            return {
                loading: false,
                timer: false,
                showChecking: false,
                debounce: null,
                model: null
            };
        },
        methods: {
            ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
            ...mapActions("DocumentsModule", [
                "GET_DOCUMENTS",
                "CREATE_DOCUMENT",
                "UPDATE_DOCUMENT",
                "UPLOAD_FILE",
                "DELETE_FILE_FROM_DOCUMENT",
            ]),
            createKi() {
                this.GET_DOCUMENTS("appl-ci").then((resp) => {
                    if (resp) {
                        if (!this.getDRAFT || (this.getDRAFT && this.getDRAFT.status_id === 2)) {
                            model.types_of_services = 'Инвестиционные паи паевых инвестиционных фондов под управлением ЗАО УК «РВМ Капитал»'
                            this.CREATE_DOCUMENT({
                                alias: "appl-ci",
                                data: model,
                            }).then((response) =>
                                !response
                                    ? this.$toast.error(`${this.getERROR}`)
                                    : this.GET_DOCUMENTS("appl-ci")
                            );
                        }
                    }
                });
            },
            onUpdate(value) {
                this.model = {...this.model, ...value};
                if (this.debounce) {
                    clearTimeout(this.debounce)
                    this.debounce = null
                }
                this.debounce = setTimeout(() => {
                    this.UPDATE_DOCUMENT({id: this.getDRAFT.id, data: this.model});
                    this.showChecking = false;
                    this.debounce = null
                }, 800)
            },

            onSibmit() {
                this.loading = true;
                this.REQUEST_SMS({
                    phone: this.getUSER.email,
                    doc_id: this.getDRAFT ? this.getDRAFT.id : null,
                    action: 'document_sign'
                }).then((success) => {
                    if (success) {
                        this.showChecking = true;
                        this.timer = true;
                        this.loading = false;
                    } else {
                        this.$toast.error(`${this.getERROR}`);
                    }
                });
            },
            onVerifiedCode(code) {
                this.CONFIRM_SMS({phone: this.getUSER.email, key: code}).then(
                    (success) => {
                        if (success) {
                            let data = {...this.getDRAFT.data};
                            data.date_sign = moment(new Date()).format("X");
                            data.signed = true;

                            this.UPDATE_DOCUMENT({id: this.getDRAFT.id, data: data});

                            if (typeof this.$route.query.next !== "undefined" && this.$route.query.next !== "") {
                                this.show_sending_documents_message("Документ отправлен.\n" + "После обработки документа Вам поступит уведомление.", false);
                                setTimeout(() => {
                                    this.$router.push("/cabinet/funds/list")
                                }, 1000);
                            } else if (typeof this.$route.query.nextTo !== "undefined" && this.$route.query.nextTo !== "") {
                                this.show_sending_documents_message();
                                setTimeout(() => {
                                    this.$router.push("/cabinet/investment/purchase-of-investment-shares/" + this.$route.query.nextTo + "?next")
                                }, 1000);
                            } else {
                                this.show_sending_documents_message();
                                setTimeout(() => {
                                if (typeof this.$route.query.to !== "undefined")
                                    this.$router.push("/cabinet/documents?next");
                                else
                                    this.$router.push("/cabinet/documents/KI");
                                }, 1000);
                            }

                        } else {
                            this.$toast.error(`${this.getERROR}`);
                        }
                    }
                );
            },
            timerIsOver(timer) {
                this.timer = timer;
            },

            async onFileDelete(type) {
              const response = await this.DELETE_FILE_FROM_DOCUMENT({id: type})
              if (response) {
                await this.GET_DOCUMENTS("appl-ci")
                this.showChecking = false
              } else this.$toast.error("Файл не был удален")
            },

            onFileLoad(file) {
                if (file.size > 5242880) {
                    this.$toast.error("Размер файла превышает 5mb")
                } else {
                    this.UPLOAD_FILE({file: file, type: 310, id: this.getDRAFT.id}).then(response => {
                        if (response.success) {
                            this.$toast.success("Файл добавлен в черновик");
                            this.GET_DOCUMENTS("appl-ci")
                        } else this.$toast.error(`${this.getERROR}`)
                    })
                }
            },

        },
        mounted() {
            model.user = this.getUSER;
            this.createKi();
        },
    };
</script>
